import './material.css';


export default function Material() {

    const widthMaxStyle = {
        width: "100%",
    };

    return (
        <div className='material'>
            <div className="material_header">
                <div>
                    <div className='logo'>
                        <a href="/"><img src="https://garoop-service.s3.ap-northeast-1.amazonaws.com/garoop_logo_service.png" width="50" height="50"></img></a>
                        <h1>Garoop</h1>
                    </div>
                </div>
            </div>
            <br></br>
            <div className="material-title">概要</div>
            <br></br>
            <div className='paddingStyle'>Garoop代表山下と世界最難関理系大学インド工科大学出身でFintechスタートアップ創業者“Himanshu氏”がタッグを組み、IT大国インドのIT新技術と最新のビジネスモデルを提供し、こどもの起業家マインドの発展に貢献していく</div>
            <br></br>
            <div className="material-title">代表者経歴</div>
            <br></br>
            <div className='paddingList'>
                <li>ITスキル全般保有</li>
                <li>会計スキル保有</li>
                <li>財務スキル保有</li>
                <li>経営スキル保有</li>
            </div>
            <br></br>
            <div style={{ textAlign: "center" }}>
                <img width={"70%"} src='https://lh3.googleusercontent.com/pw/ABLVV86WL3ELAZg5C5pPUtQK-MLDedWHEwkQMWrMZ0j_MPN1uI7QxdsBJhV6VHGvyloQsQtJxulbnRcJynIMEA4GXTcgHeBPkHE5sHxJtFwsdAwtvlqtTDXCArPz60I8GvkbU4oU1n_YtVAX18TlONwt5NzO=w1300-h738-s-no-gm?authuser=0'></img>
            </div>
            <br></br>
            <div className="material-title">Himanshu</div>
            <br></br>
            <div className='paddingList'>
                <li>MBA保有</li>
                <li>コンサル経験保有</li>
                <li>M&A経験保有</li>
                <li>ITスキル全般保有</li>
                <li>会計スキル保有</li>
                <li>財務スキル保有</li>
                <li>経営スキル保有</li>
            </div>
            <br></br>
            <div style={{ textAlign: "center" }}>
                <img width={"70%"} src='https://lh3.googleusercontent.com/pw/ABLVV85XTPG0URV-Soo0UX80QoedQFxFDSbifIxg3ncMfgSxBLKudmaAmyZRbfXH2KyMhR6cAQI7LbK5BuKiT6beSb6eQow3eIJ5lnZm6n2fBtw_5mb4TFUMp0sC-evW6RYQIVe0GH3y64KUFrsUAMN7lTn_=w2392-h1340-s-no-gm?authuser=0'></img>
            </div>
            <br></br>
            <div className="material-title">紹介資料</div>
            <br></br>
            <table style={widthMaxStyle}>
                <tbody>
                    <tr>
                        <td width={"10%"}></td>
                        <td>
                            <a href='https://drive.google.com/file/d/1PuaGMt7fEqiC_hX-c5NEFGZ23zV7lUHI/view?usp=drive_link' >Garoop資金調達プレゼン資料</a>
                        </td>
                        <td width={"10%"}></td>
                    </tr>
                    <tr>
                        <td width={"10%"}></td>
                        <td>
                            <a href='https://drive.google.com/file/d/1O5Ji4hWKOiSTZSB7JZVzWIAr7b7uBQSy/view?usp=drive_link' >Garoopサービス紹介資料</a>
                        </td>
                        <td width={"10%"}></td>
                    </tr>
                    <tr>
                        <td width={"10%"}></td>
                        <td>
                            <a href='https://drive.google.com/file/d/1Ski5F72vOvwpKcW78lsakNtJVx5lergi/view?usp=sharing'>Garoopクラウドサービス資料（協賛募集中）</a>
                        </td>
                        <td width={"10%"}></td>
                    </tr>
                    <tr>
                        <td width={"10%"}></td>
                        <td>
                            <a href='https://garoop.notion.site/Garoop-53dae94331ec4528830c71add517ed01?pvs=4'>インド進出したい日本企業向け資料（Garoopでサポート）</a>
                        </td>
                        <td width={"10%"}></td>
                    </tr>
                    <tr>
                        <td width={"10%"}></td>
                        <td>
                            <a href='https://abc-online.zohosites.com/files/Gujarat_A%20Pioneering%20State.pdf'>GaroopビジネスパートナーHimanshu氏によるインド経済講演資料</a>
                        </td>
                        <td width={"10%"}></td>
                    </tr>
                    <tr>
                        <td width={"10%"}></td>
                        <td>
                            <a href='https://abc-online.zohosites.com/files/%E4%B8%96%E7%95%8C%E3%81%8B%E3%82%89%E6%B3%A8%E7%9B%AE%E3%81%95%E3%82%8C%E3%82%8B%E3%82%A4%E3%83%B3%E3%83%89%20%E6%9C%80%E7%B5%82%202023.9.27.pdf'>Garoop会長西橋氏によるインド講演資料</a>
                        </td>
                        <td width={"10%"}></td>
                    </tr>
                    <tr>
                        <td width={"10%"}></td>
                        <td>
                            <a href='https://www.nchs.nagasaki-city.ed.jp/blogs/blog_entries/view/9/92569fa13b4f74b71392661d8791d469?frame_id=42' >長崎商業高校講演</a>
                        </td>
                        <td width={"10%"}></td>
                    </tr>
                </tbody>
            </table>
            <div className="paddingStyle">
                <a href='https://instaencer.com/'><img width={"70%"} src='https://cdn.peraichi.com/userData/5f977374-aa5c-41fe-932a-09310a000054/img/65732e0f9ede0/original.png'></img></a>
                <br></br>
                <br></br>
                <div>上のInstaEncerは、Instagramのフォロワーや「いいね」を劇的に増やす革新的なサービスです。パスワード不要で安全にフォロワーを獲得し、もし増えなければ全額返金保証。日本人フォロワーや女性フォロワーのオプションもあり、LINEサポートで24時間対応。あなたのSNS影響力を一気に高め、注目の的に。信頼性と効果を重視し、フォロワーを増やすならInstaEncerが最適です。夢のインスタグラム成功への第一歩を踏み出しましょう。</div>
                <div>インスタのフォロワーを購入するとは、文字通り一定金額を支払うことで一定数のアカウントにフォローをしてもらうことです。「フォロワー数が多ければ多いほど、アカウントの信頼性が上がるから」という理由から、企業のアカウントやブランドなどでも行っているところが多いのが現状です。中でもインスタエンサーでフォロワーを利用するメリットとしては、まず、迅速な効果が挙げられます。また、インスタエンサーでは、ターゲットに合わせたフォロワーを選択することも可能です。自身のアカウントのテーマや目的に合わせたフォロワーを選ぶことで、より効果的なマーケティングやプロモーション活動ができます。</div>
            </div>
        </div>

    )
}