import { useState } from 'react';
import './generative_ai.css';
import chatbotImage　from '../img/generative_ai/chatbot.png';
import aivoicefrom from '../img/generative_ai/aivoice.png';
import aiVideo from '../img/generative_ai/aivideo.png';
import aiTraining from '../img/generative_ai/aitraining.png';
import aiServiceImage from '../img/generative_ai/aiservice.png';



export default function GenerativeAi() {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };


    return (
        <div>
            <header id="main-header">
                <div className="container">
                    <nav>
                        <div className="logo">
                            <img src="https://garoop-service.s3.ap-northeast-1.amazonaws.com/garoop_logo_service.png" alt="Garoopロゴ" className="logo-img" />
                            <h1><a href="#">Garoop</a></h1>
                        </div>
                        <div className="contact">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLScAQyFYrJD2ELoDTNyjVhIh3KsMPN35Rm-kpTXcpt06_wQbcA/viewform?usp=sf_link" target="_blank" className="btn">お問い合わせ</a>
                        </div>
                    </nav>
                </div>
            </header>
            <main>
                <section id="hero">
                    <div className="hero-content">
                        <h1><span><span className='geneAIText'>"生成AI"</span><span>で業務DX</span></span></h1>
                        <p className='fontweight'>私たちの生成AI技術で、次世代のビジネスをリードしませんか？</p>
                        <a href="#details" className="btn">サービス内容を見る</a>
                    </div>
                </section>
                <section id="details">
                    <div className="container">
                        <h3>AI Training</h3>
                        <img src={aiTraining}></img>
                        <p>私たちのAI Trainingプログラムは、生成AIやプログラミングに関する高度な研修を提供します。企業の成長を加速させるためのスキルと知識を短期間で習得できます。</p>
                        <p>（料金例）1人あたり1ヶ月9万円</p>
                        <a href="https://www.nchs.nagasaki-city.ed.jp/blogs/blog_entries/view/9/92569fa13b4f74b71392661d8791d469?frame_id=42" className="btn">AI Training実績</a>
                        <br></br>
                        <br></br>

                        <h3>AI Service</h3>
                        <img src={aiServiceImage}></img>
                        <p>私たちのAI Serviceは、ChatGPTや音声生成AIなど、最新のAI技術を駆使したサービスを迅速に構築します。企業の効率化と革新を支援し、競争力を高めるための強力なツールを提供します。</p>
                        <p>（料金例）初期費用200万円〜 + メンテナンス料:月額30万円〜</p>
                        <a href="https://create.garoop.jp/" className="btn">AI Service実績</a>
                        <br></br>
                        <br></br>

                        <h3>AI Voice</h3>
                        <img src={aivoicefrom}></img>
                        <p>AI Voiceサービスでは、任意のテキストをリアルタイムで自然な音声に変換します。高品質な音声生成技術を用いて、顧客対応や音声案内など、さまざまなシナリオで活用できます。</p>
                        <p>（料金例）初期費用50万円〜 + メンテナンス料:月額15万円〜</p>
                        <a href="https://create.garoop.jp/communication" className="btn">AI Voice実績</a>
                        <br></br>
                        <br></br>

                        <h3>AI Video & Image</h3>
                        <img src={aiVideo}></img>
                        <p>AI Video & Imageサービスでは、生成AIを用いて魅力的な動画アバターや画像を作成します。マーケティングやプロモーションに最適なビジュアルコンテンツを短期間で提供します。。</p>
                        <p>（料金例）画像:1件2万円〜、動画:1件10万円〜</p>
                        <a href="https://www.youtube.com/watch?v=arWQxsfD-Ic" className="btn">AI Video実績</a>
                        <br></br>
                        <br></br>

                        <h3>AI Chatbot & WorkFlow</h3>
                        <img src={chatbotImage}></img>
                        <p>私たちのAI Chatbot & WorkFlowソリューションは、データを活用して問い合わせや自動メール送信を迅速に処理します。最短1日での導入が可能で、業務効率を大幅に向上させます。</p>
                        <p>（料金例）初期費用20万円〜 + メンテナンス料:月額2万円〜</p>
                        <a href="https://udify.app/chat/IsXYGsxPMeveONqy" className="btn">AI Chatbot実績</a>
                        <br></br>
                        <br></br>
                    </div>
                </section>
                <section id="process">
                    <div className="container">
                        <h3>導入までの流れ</h3>
                        <div className="process-step">
                            <h4>ステップ1: お問い合わせ</h4>
                            <p>まずはお問い合わせフォームからご連絡ください。</p>
                        </div>
                        <div className="process-step">
                            <h4>ステップ2: ヒアリング</h4>
                            <p>お客様の要件を詳しくお伺いします。</p>
                        </div>
                        <div className="process-step">
                            <h4>ステップ3: 提案・お見積もり</h4>
                            <p>最適なプランをご提案し、お見積もりを提出いたします。</p>
                        </div>
                        <div className="process-step">
                            <h4>ステップ4: 導入・運用</h4>
                            <p>導入作業を進め、運用をサポートいたします。</p>
                        </div>
                    </div>
                </section>

                <section id="faq">
                    <div className="container">
                        <h3>よくある質問 (FAQ)</h3>
                        <div className="faq-item">
                            <h4>質問1: サービスの導入までどのくらいの時間がかかりますか？</h4>
                            <p>回答: サービスの内容によりますが、通常は1ヶ月以内に導入可能です。</p>
                        </div>
                        <div className="faq-item">
                            <h4>質問2: サポートはどのように受けられますか？</h4>
                            <p>回答: メールや電話でのサポートを提供しております。詳細はお問い合わせください。</p>
                        </div>
                        <div className="faq-item">
                            <h4>質問3: カスタマイズは可能ですか？</h4>
                            <p>回答: はい、お客様のニーズに合わせたカスタマイズが可能です。</p>
                        </div>
                        <div className="faq-item">
                            <h4>質問4: 料金プランはどのようになっていますか？</h4>
                            <p>回答: 料金プランはサービス内容により異なります。詳細はヒアリングしますのでお問い合わせください。</p>
                        </div>
                    </div>
                </section>

                <section id="video">
                    <div className="container">
                        <h3>お問い合わせはこちらから</h3>
                        <br></br>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLScAQyFYrJD2ELoDTNyjVhIh3KsMPN35Rm-kpTXcpt06_wQbcA/viewform?usp=sf_link" target="_blank" className="btn">お問い合わせ</a>
                    </div>
                </section>

            </main>
            <footer>
                <div className="container">
                    <p>&copy; 2024 株式会社Garoop</p>
                </div>
            </footer>
        </div>
    )
}