import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Material from './material/material';
import GenerativeAi from './generative_ai/generative_ai';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" Component={App} />
      <Route path="/material" Component={Material} />
      <Route path="/generative" Component={GenerativeAi} />
      {/* 他のルート */}
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
